<template>
  <jc-form
    :model="form"
    :rules="rules"
    ref="form"
    :label-width="labelWidth"
    label-position="left"
    class="jc-form-tiny-width"
  >
    <jc-form-item prop="ownerName" label="姓名：">
      <!-- <dz-input v-model.trim="form.ownerName" type="text" :placeholder="name === 'step1' ? '请尽量填写法人信息' : '请填写实际公司网站负责人信息'" id="noStepFirstOwnerName" :disabled="display" dzType="name"></dz-input> -->
      <jc-input
        v-if="isShowField('ownerName')"
        v-model.trim="form.ownerName"
        type="text"
        :placeholder="name === 'step1' ? '请尽量填写法人信息' : '请填写实际公司网站负责人信息'"
        :disabled="display"
      ></jc-input>
    </jc-form-item>
    <jc-form-item v-if="isShowField('ownerIdentityType')" prop="ownerIdentityType" label="证件类型：" required>
      <jc-select v-model="form.ownerIdentityType" placeholder="请选择" :disabled="display" @change="handleChange">
        <jc-option
          v-for="itemOption in priIdentityTypeOptions"
          :key="itemOption.code"
          :label="itemOption.name"
          :value="itemOption.code"
        >
        </jc-option>
      </jc-select>
    </jc-form-item>
    <jc-form-item v-if="isShowField('ownerIdentityNo')" prop="ownerIdentityNo" label="证件号码：">
      <jc-input
        v-model.trim="form.ownerIdentityNo"
        type="text"
        placeholder="请输入证件号码"
        :disabled="display"
      ></jc-input>
    </jc-form-item>
    <jc-form-item prop="ownerIdentityNoValidityPeriod" label="证件有效期：">
      <validity-period v-model="form.ownerIdentityNoValidityPeriod" :disabled="display"></validity-period>
    </jc-form-item>
    <jc-form-item v-if="isShowField('principalPhone')" label="办公电话：">
      <jc-row>
        <jc-col :span="4">
          <jc-form-item prop="phonGlobalRoaming">
            <jc-input
              v-model.trim="form.phonGlobalRoaming"
              type="text"
              placeholder="国家编码"
              :disabled="display"
            ></jc-input>
          </jc-form-item>
        </jc-col>
        <jc-col :span="4" :offset="1">
          <jc-form-item prop="phoneAreaCode">
            <jc-input v-model.trim="form.phoneAreaCode" type="text" placeholder="区号" :disabled="display"></jc-input>
          </jc-form-item>
        </jc-col>
        <jc-col :span="14" :offset="1">
          <jc-form-item prop="phoneWithoutAreaCode">
            <jc-input
              v-model.trim="form.phoneWithoutAreaCode"
              type="text"
              placeholder="电话"
              :disabled="display"
            ></jc-input>
          </jc-form-item>
        </jc-col>
      </jc-row>
    </jc-form-item>
    <jc-form-item prop="mobile" label="手机号码：">
      <!-- <dz-input v-model.trim="form.mobile" type="text" placeholder="请输入手机号" :disabled="display" dzType="mobile"></dz-input> -->
      <jc-input v-model.trim="form.mobile" type="text" placeholder="请输入手机号" :disabled="display"></jc-input>
      <div v-html="phoneRule" v-show="phoneRule"></div>
    </jc-form-item>
    <template v-if="!display">
      <jc-form-item prop="" label="手机验证码：" v-if="!!infoData.mobile && infoData.mobile === form.mobile">
        <div style="display:flex;justify-content:space-between">
          <jc-input
            v-model.trim="form.smsCode"
            type="text"
            placeholder="号码已验证"
            style="width:210px"
            disabled
          ></jc-input>
          <jc-button type="primary" disabled>获取手机验证码</jc-button>
        </div>
      </jc-form-item>
      <jc-form-item prop="smsCode" label="手机验证码：" v-else>
        <div style="display:flex;justify-content:space-between">
          <jc-input
            v-model.trim="form.smsCode"
            type="text"
            placeholder="请输入手机验证码"
            style="width:210px"
          ></jc-input>
          <jc-button type="primary" @click="getSmsCode()">获取手机验证码</jc-button>
        </div>
      </jc-form-item>
    </template>

    <jc-form-item prop="emergencyCall" label="应急电话号码：">
      <!-- <dz-input v-model.trim="form.emergencyCall" type="text" placeholder="请输入手机号" :disabled="display"  dzType="mobile"></dz-input> -->
      <jc-input v-model.trim="form.emergencyCall" type="text" placeholder="请输入手机号" :disabled="display"></jc-input>
      <div v-html="emergencyPhoneRule" v-show="emergencyPhoneRule"></div>
    </jc-form-item>
    <jc-form-item prop="email" label="电子邮箱地址：">
      <!-- <dz-input v-model.trim="form.email" type="text" placeholder="请输入电子邮箱地址" :disabled="display" dzType="mail"></dz-input> -->
      <jc-input v-model.trim="form.email" type="text" placeholder="请输入电子邮箱地址" :disabled="display"></jc-input>
    </jc-form-item>
    <jc-form-item v-if="!website && isShowField('principalRemark')" prop="remark" label="主体备注信息：">
      <jc-input v-model.trim="form.remark" type="textarea"></jc-input>
    </jc-form-item>
  </jc-form>
</template>
<script>
import { OWNER_COMMUNITY_RULE } from '@/views/service/rules'
import { getDictionary, sendMobileSms, queryRule } from '@/service/record-api'
import ValidityPeriod from '@/components/validity-period'

export default {
  inject: ['modifyIdentitype'],
  data() {
    return {
      name: this.$route.name,
      form: {
        ownerName: '', // 姓名
        ownerIdentityType: '', // 证件类型
        ownerIdentityNo: '', // 证件号码
        phonGlobalRoaming: '086', // 国家编码
        phoneAreaCode: '', // 座机区号
        phoneWithoutAreaCode: '', // 座机号码
        mobile: '',
        emergencyCall: '',
        email: '',
        remark: '',
        smsCode: '',
        ownerIdentityNoValidityPeriod: '' // 证件有效期
      },
      priIdentityTypeOptions: [],
      phoneRule: '',
      emergencyPhoneRule: '',
      rules: OWNER_COMMUNITY_RULE.call(this)
    }
  },
  props: {
    infoData: {
      type: Object
    },
    // 主体信息页填写的信息内容
    ownerPrincipal: Object,
    // 网站信息一件勾选填写负责人信息
    display: Boolean,
    // 网站信息不显示备注
    website: Boolean,
    labelWidth: String
  },
  computed: {},
  watch: {
    async infoData() {
      this.getInit()
      await this.getTextRules()
    },
    display(val) {
      if (val) {
        this.principalDisplay()
      } else {
        this.getInit()
      }
    }
  },
  components: {
    ValidityPeriod
  },
  mixins: [],
  mounted() {},
  async created() {
    await this.getOrganizersNatureOption()
    this.getInit()
    this.getTextRules()
  },
  filters: {},
  methods: {
    // 信息回显
    getInit() {
      if (Object.keys(this.infoData).length === 0) return
      let phoneArr = this.infoData.phone && this.infoData.phone.split('-')
      if (phoneArr && phoneArr.length) {
        if (phoneArr.length === 2) {
          this.form.phoneAreaCode = this.infoData.phone.split('-')[0]
          this.form.phoneWithoutAreaCode = this.infoData.phone.split('-')[1]
        } else if (phoneArr.length === 3) {
          this.form.phonGlobalRoaming = this.infoData.phone.split('-')[0]
          this.form.phoneAreaCode = this.infoData.phone.split('-')[1]
          this.form.phoneWithoutAreaCode = this.infoData.phone.split('-')[2]
        }
      }
      this.form.ownerName = this.infoData.ownerName || ''
      this.form.ownerIdentityType = this.infoData.ownerIdentityType || 2
      this.form.ownerIdentityNo = this.infoData.ownerIdentityNo || ''
      this.form.mobile = this.infoData.mobile || ''
      this.form.emergencyCall = this.infoData.emergencyCall || ''
      this.form.email = this.infoData.email || ''
      this.form.remark = this.infoData.remark || ''
      this.form.ownerIdentityNoValidityPeriod = this.infoData.ownerIdentityNoValidityPeriod || ''
    },
    // 网站信息页面一键填写负责人信息
    principalDisplay() {
      this.form.ownerName = this.ownerPrincipal.ownerName || ''
      this.form.ownerIdentityType = this.ownerPrincipal.ownerIdentityType || ''
      this.form.ownerIdentityNo = this.ownerPrincipal.ownerIdentityNo || ''
      this.form.mobile = this.ownerPrincipal.mobile || ''
      this.form.emergencyCall = this.ownerPrincipal.emergencyCall || ''
      this.form.email = this.ownerPrincipal.email || ''
      this.form.ownerIdentityNoValidityPeriod = this.ownerPrincipal.ownerIdentityNoValidityPeriod || ''
      let phoneArr = this.ownerPrincipal.phone && this.ownerPrincipal.phone.split('-')
      if (phoneArr && phoneArr.length) {
        if (phoneArr.length === 2) {
          this.form.phoneAreaCode = this.ownerPrincipal.phone.split('-')[0]
          this.form.phoneWithoutAreaCode = this.ownerPrincipal.phone.split('-')[1]
        } else if (phoneArr.length === 3) {
          this.form.phonGlobalRoaming = this.ownerPrincipal.phone.split('-')[0]
          this.form.phoneAreaCode = this.ownerPrincipal.phone.split('-')[1]
          this.form.phoneWithoutAreaCode = this.ownerPrincipal.phone.split('-')[2]
        }
      }
    },
    //获取文本规则
    async getTextRules() {
      try {
        let { data } = await queryRule({
          type: this.role == 5 ? 0 : 1,
          provinceCode: this.infoData.provinceCode
        })
        this.phoneRule = data.find(item => item.fieldCode === 1) ? data.find(item => item.fieldCode === 1).content : ''
        this.emergencyPhoneRule = data.find(item => item.fieldCode === 2)
          ? data.find(item => item.fieldCode === 2).content
          : ''
      } catch (err) {
        console.log(err)
      }
    },
    // 获取个人的证件类型
    async getOrganizersNatureOption() {
      try {
        let { data } = await getDictionary({
          code: 5,
          type: 'organizersnature'
        })
        this.priIdentityTypeOptions = data
        this.form.ownerIdentityType = this.priIdentityTypeOptions[0].code
      } catch (err) {
        console.log(err)
      }
    },
    handleChange() {
      if (this.website) {
        this.modifyIdentitype(this.form.ownerIdentityType)
      }
    },
    // 主体负责人字段转网站负责人字段
    manageWebsitParams() {
      const {
        ownerIdentityType,
        ownerIdentityNo,
        ownerIdentityNoValidityPeriod,
        phoneWithoutAreaCode,
        phonGlobalRoaming,
        ...other
      } = this.form
      return {
        ...other,
        identityType: ownerIdentityType,
        identityNo: ownerIdentityNo,
        identityNoValidityPeriod: ownerIdentityNoValidityPeriod,
        phoneWithoutAreaCode,
        phonGlobalRoaming: phoneWithoutAreaCode && phonGlobalRoaming
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.website) {
              resolve(this.manageWebsitParams())
            } else {
              resolve(this.form)
            }
          } else {
            reject(new Error('submit false'))
          }
        })
      })
    },
    async getSmsCode() {
      if (!this.form.mobile) return

      this.$refs.form.validateField('mobile', async errorMsg => {
        if (!errorMsg) {
          try {
            const result = await sendMobileSms({ mobile: this.form.mobile })
            if (!result.error) {
              this.$message.success('发送成功')
            } else {
              this.$message.error(result.error)
            }
          } catch (error) {
            this.form.smsCode = ''
            this.$message.error(error.message)
          }
        }
      })
    }
  }
}
</script>
